import { lazy } from 'react';

const AdPage = lazy(() => import('./pages/AdPage'));
const AdsPage = lazy(() => import('./pages/AdsPage'));
const CardPage = lazy(() => import('./pages/CardPage'));
const CreatePasswordPage = lazy(() => import('./pages/CreatePasswordPage'));
const DashboardPage = lazy(() => import('./pages/DashboardPage'));
const IndexPage = lazy(() => import('./pages/IndexPage'));
const LoginPage = lazy(() => import('./pages/LoginPage'));
const NoAccessPage = lazy(() => import('./pages/NoAccessPage'));
const NotFoundPage = lazy(() => import('./pages/NotFoundPage'));
const NotificationsPage = lazy(() => import('./pages/NotificationsPage'));
const ResetPasswordPage = lazy(() => import('./pages/ResetPasswordPage'));
const SettingsPage = lazy(() => import('./pages/SettingsPage'));
const TeamPage = lazy(() => import('./pages/TeamPage'));
const TeamMemberPage = lazy(() => import('./pages/TeamMemberPage'));
const TopicsPage = lazy(() => import('./pages/TopicsPage'));
const TopicPage = lazy(() => import('./pages/TopicPage'));
const UsersPage = lazy(() => import('./pages/UsersPage'));
const UserPage = lazy(() => import('./pages/UserPage'));

const routes = [
  {
    path: 'cards/:id',
    element: <CardPage />,
  },
  {
    path: 'create-password/:token',
    element: <CreatePasswordPage />,
  },
  {
    path: '/',
    element: <IndexPage />,
  },
  {
    path: 'login',
    element: <LoginPage />,
  },
  {
    path: '403',
    element: <NoAccessPage />,
  },
  {
    path: 'advertising',
    element: <AdsPage />,
  },
  {
    path: 'advertising/:id',
    element: <AdPage />,
  },
  {
    path: 'notifications',
    element: <NotificationsPage />,
  },
  {
    path: 'reset-password',
    element: <ResetPasswordPage />,
  },
  {
    path: 'reset-password/:token',
    element: <CreatePasswordPage />,
  },
  {
    path: 'settings',
    element: <SettingsPage />,
  },
  {
    path: 'stats',
    element: <DashboardPage />,
  },
  {
    path: 'team',
    element: <TeamPage />,
  },
  {
    path: 'team/:id',
    element: <TeamMemberPage />,
  },
  {
    path: 'topics',
    element: <TopicsPage />,
  },
  {
    path: 'topics/:id',
    element: <TopicPage />,
  },
  {
    path: 'users',
    element: <UsersPage />,
  },
  {
    path: 'users/:id',
    element: <UserPage />,
  },

  {
    path: '*',
    element: <NotFoundPage />,
  },
];

export default routes;
